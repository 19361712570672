import React, { Component, Fragment } from 'react';
import './family-tree.scss';
import FamilyTreeCow from './FamilyTreeCow';
import PropTypes from 'prop-types';
import BackToTop from '../../util/buttons/components/BackToTop';
import GeneticHoofprintLogo from '../../util/logos/GeneticHoofprintLogo';
import InfoIconThick from '../../util/icons/components/InfoIconThick';
import { Link } from 'react-router-dom';
import { getGBIDomain } from '../../util/site';
// import buildAncestors from '../../admin/util/buildAncestors';

const buildBlocks = count => {
  const blocks = [];
  for (let i = 0; i < count; i++) {
    blocks.push(<div key={i} className={'block block-' + (i + 1)}></div>);
  }
  return <Fragment>{ blocks }</Fragment>;
};

const getCowIfExists = (cow, ...args) => {
  for (let i = 0; i < args.length; i++) {
    try {
      cow = cow[args[i]];
    } catch (error) {
      return {};
    }
  }
  return cow;
};

class FamilyTree extends Component {
  constructor (props) {
    super(props);
    this.state = {
      dragging: false,
      currentAdjustmentX: null,
      maxAdjustment: 0,
      margin: 20
    };
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchCancel = this.handleTouchCancel.bind(this);
    this.setupTheTree = this.setupTheTree.bind(this);
  }
  setupTheTree () {
    const tree = document.getElementById('dragable-family-tree');
    const container = document.getElementById('cow-family-tree');
    const treeWidth = tree.clientWidth;
    const containerWidth = container.clientWidth;
    if (treeWidth > containerWidth) {
      const currentAdjustmentX = (containerWidth - treeWidth) / 2;
      this.setState({
        currentAdjustmentX,
        maxAdjustment: Math.abs(currentAdjustmentX * 2) + this.state.margin * 2
      }, () => {
        tree.style.transform = `translateX(${currentAdjustmentX}px`;
      });
    }
  }
  componentDidMount () {
    this.setupTheTree();
    window.addEventListener('resize', this.setupTheTree);
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.setupTheTree);
  }
  handleTouchStart (event) {
    const dragStartX = event.changedTouches[0].clientX;
    this.setState({ dragStartX });
  }
  handleTouchMove (event) {
    const touchPosX = event.changedTouches[0].clientX;
    const dragAdjustmentX = touchPosX - this.state.dragStartX;
    let currentAdjustmentX = dragAdjustmentX + this.state.currentAdjustmentX;
    if (currentAdjustmentX > this.state.margin * 2) {
      currentAdjustmentX = this.state.margin * 2;
    } else if (currentAdjustmentX < (-1 * this.state.maxAdjustment)) {
      currentAdjustmentX = (-1 * this.state.maxAdjustment);
    }
    const el = document.getElementById('dragable-family-tree');
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
    });
  }
  handleTouchEnd (event) {
    const touchPosX = event.changedTouches[0].clientX;
    const dragAdjustmentX = touchPosX - this.state.dragStartX;
    let currentAdjustmentX = dragAdjustmentX + this.state.currentAdjustmentX;
    if (currentAdjustmentX > this.state.margin * 2) {
      currentAdjustmentX = this.state.margin * 2;
    } else if (currentAdjustmentX < (-1 * this.state.maxAdjustment)) {
      currentAdjustmentX = (-1 * this.state.maxAdjustment);
    }
    const el = document.getElementById('dragable-family-tree');
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
      this.setState({ currentAdjustmentX });
    });
  }
  handleTouchCancel (event) {
    const el = document.getElementById('dragable-family-tree');
    const currentAdjustmentX = this.state.currentAdjustmentX;
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
    });
    this.setState({ dragging: false });
  }

  /* eslint-disable max-len */
  render () {
    const cow = this.props.cow;
    return (
      <article id='cow-family-tree' className='bb-page-wrapper'>
        <div className="border"></div>
        <BackToTop />
        <div className="ancestory-wrapper">
          <h2>
            <Link to={ `${getGBIDomain()}/good-beef/genetics` } className="genetics-link">
              <GeneticHoofprintLogo />
              <InfoIconThick />
            </Link>
          </h2>
          <div onTouchStart={ this.handleTouchStart }
            onTouchMove={ this.handleTouchMove }
            onTouchEnd={ this.handleTouchEnd }
            onTouchCancel={ this.handleTouchCancel }
            id="dragable-family-tree"
            data-cy="ancestry-display"
            className='bb-family-tree'>
            <div className='top-row'>
              <FamilyTreeCow cow={ cow } className="cow" />
              { buildBlocks(4) }
            </div>
            <div className='second-row'>
              <FamilyTreeCow
                cow={ getCowIfExists(cow, 'ancestors', 'sire')}
                className='sire' descriptor='sire' />
              <FamilyTreeCow
                cow={ getCowIfExists(cow, 'ancestors', 'dam')}
                className='dam' descriptor='dam' />
              { buildBlocks(8) }
            </div>
            <div className='third-row'>
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'sire', 'ancestors', 'sire')}
                className='grand-sire-1' descriptor='sire' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'sire', 'ancestors', 'dam')}
                className='grand-dam-1' descriptor='dam' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'dam', 'ancestors', 'sire'
                )}
                className='grand-sire-2' descriptor='sire' />
              <FamilyTreeCow
                cow={ getCowIfExists(cow, 'ancestors', 'dam', 'ancestors', 'dam')}
                className='grand-dam-2' descriptor='dam' />
              { buildBlocks(16) }
            </div>
            <div className='fourth-row'>
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'sire',
                  'ancestors', 'sire', 'ancestors', 'sire'
                )}
                className='gg-sire-1' descriptor='sire' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'sire', 'ancestors',
                  'sire', 'ancestors', 'dam'
                )}
                className='gg-dam-1' descriptor='dam' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'sire', 'ancestors',
                  'dam', 'ancestors', 'sire'
                )}
                className='gg-sire-2' descriptor='sire' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'sire', 'ancestors', 'dam', 'ancestors', 'dam'
                )}
                className='gg-dam-2' descriptor='dam' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'dam', 'ancestors',
                  'sire', 'ancestors', 'sire'
                )}
                className='gg-sire-3' descriptor='sire' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'dam', 'ancestors', 'sire', 'ancestors', 'dam'
                )}
                className='gg-dam-3' descriptor='dam' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'dam', 'ancestors', 'dam', 'ancestors', 'sire'
                )}
                className='gg-sire-4' descriptor='sire' />
              <FamilyTreeCow
                cow={ getCowIfExists(
                  cow, 'ancestors', 'dam', 'ancestors', 'dam', 'ancestors', 'dam'
                )}
                className='gg-dam-4' descriptor='dam' />
              { buildBlocks(32) }
            </div>
          </div>
        </div>
      </article>
    );
  }
}

FamilyTree.propTypes = {
  cow: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tag: PropTypes.string
    // ancestors: PropTypes.object.isRequired
  })
};

export default FamilyTree;
