import React from 'react';
import PropTypes from 'prop-types';
import './download-icon.scss';

/* eslint-disable max-len */
const DownloadIcon = ({ onClick }) => (
  <svg onClick={ onClick } className='bb-download-icon' viewBox="0 0 97.11 98.81">
    <path d="M93.39,61.55a5.17,5.17,0,0,0-5.17,5.17V89.08H11.78V66.72a5.17,5.17,0,1,0-10.33,0V94.24a5.17,5.17,0,0,0,5.16,5.17H93.39a5.17,5.17,0,0,0,5.16-5.17V66.72A5.16,5.16,0,0,0,93.39,61.55Z" transform="translate(-1.45 -0.59)"/>
    <path className="arrow" d="M46.34,70.31a3.33,3.33,0,0,0,.39.36l.15.11.25.19.21.12.23.14.22.1a1.75,1.75,0,0,0,.24.11l.22.08.25.09.23.06.26.07.27,0,.23,0a4.35,4.35,0,0,0,1,0l.23,0,.27,0,.26-.07.23-.06.25-.09.22-.08a1.75,1.75,0,0,0,.24-.11l.22-.1.23-.14.21-.12.25-.19.15-.11a3.33,3.33,0,0,0,.39-.36L84.8,39.18a5.17,5.17,0,0,0-7.31-7.3L55.16,54.2V5.76a5.16,5.16,0,1,0-10.32,0V54.2L22.51,31.88a5.17,5.17,0,0,0-7.31,7.3Z" transform="translate(-1.45 -0.59)"/>
  </svg>
);

DownloadIcon.propTypes = {
  onClick: PropTypes.func
};

export default DownloadIcon;
